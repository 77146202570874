@import '~dauphine/mixins';
@import '../../core/helpers/variables';
@import '../../core/helpers/functions';
@import '../../core/mixins/mixins';

.w-error{
  padding: rem(40) 0;

  @include breakpoint(md){
    padding: rem(80) 0;
  }

  .error{
    display: flex;
    flex-direction: column;
    padding: 0 rem(12);

    @include breakpoint(sm){
      padding: 0;
      flex-direction: row;
    }

    &__title{
      position: relative;
      max-width: 80%;
      margin-bottom: rem(40);

      @include breakpoint(sm){
        max-width: 45%;
        margin-bottom: 0;
      }
      @include breakpoint(md){
        @include offset(1,12);
        max-width: 29%;
      }
    }

    &__text{
      /*@include breakpoint(md){
        @include offset(1,12);
      }*/

      .error__btn{
        text-decoration: none;
        margin-top: rem(40);
      }
    }
  }
}